import * as React from 'react';

import { Container, NavButton } from './styles';

import { Link } from "react-router-dom";

export const NavBar = () => {

    return (
        <Container >

            <NavButton>
                <Link to="/home" >HOME</Link>
            </NavButton>
            <NavButton id="LOGIN">
                <Link to="/auth" >LOGIN</Link>
            </NavButton>
            <NavButton>
                <Link to="/pokemon" >POKEMON</Link>
            </NavButton>
            {/* <NavButton href="https://gaussbit.com/PHP/WebGL.php." >WEBGL</NavButton> */}
            <NavButton>
                <Link to="/gpt">CHAT</Link>
            </NavButton>
            <NavButton>
                <Link to="/aboutme">ABOUT</Link>
            </NavButton>
            <NavButton>
                <Link to="/unauth">LOGOUT</Link>
            </NavButton>

        </Container>
    );
};