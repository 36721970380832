import * as React from 'react';

import { AboutMeComponent } from "../../../components/AboutMeComponent/AboutMeComponent";

import { AboutMeProvider } from "../../../hooks//aboutMe.hook";

export function AboutMePlugin() {
    return (
        <AboutMeProvider>
            <AboutMeComponent />
        </AboutMeProvider>
    )
}