import styled from "styled-components"


export const Container = styled.div`

    background: rgba(225,245,250,0.9);
    border-radius:15px;
	box-shadow: 1px 2px 10px #000 ;
    margin: 5px;
    padding: 10px;
    color: rgb(100,135,155);

`