import * as React from 'react';

import { AuthComponent } from "../../../components/AuthComponent/AuthComponent";

import { AuthProvider } from "../../../hooks/auth.hook";

export function AuthPlugin() {
    return (
        <AuthProvider>
            <AuthComponent />
        </AuthProvider>
    )
}