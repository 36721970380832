import * as React from 'react';

import { ChakraProvider } from '@chakra-ui/react'

import { MainPlugin } from './pages/plugins/main'
import { AuthPlugin } from './pages/plugins/auth'
import { UnauthPlugin } from './pages/plugins/auxPlugins/unauth'
import { HomePlugin } from './pages/plugins/home'
import { PokemonPlugin } from './pages/plugins/pokemon'
import { GPTPlugin } from './pages/plugins/gpt'
import { AboutMePlugin } from './pages/plugins/aboutMe'

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

const App: React.FC = () => {

  const router = createBrowserRouter([
    {
      path: "/",
      element: <MainPlugin />,
      // errorElement: <ErrorPage />,
      children: [
        {
          path: "/auth",
          element: <AuthPlugin />,
        },
        {
          path: "/home",
          element: <HomePlugin />,
        },
        {
          path: "/pokemon",
          element: <PokemonPlugin />,
        },
        {
          path: "/gpt",
          element: <GPTPlugin />,
        },
        {
          path: "/aboutme",
          element: <AboutMePlugin />,
        },
        {
          path: "/unauth",
          element: <UnauthPlugin />,
        },
      ],
    },
  ]);

  return (
    <ChakraProvider>
      <RouterProvider router={router} />
    </ChakraProvider>
  );
}

export default App;
