import * as React from 'react';

import { Container } from './styles';

import { useAuth } from '../../hooks/auth.hook'

import {
    Button,
    FormControl,
    FormLabel,
    Input,
    Checkbox,
    Link,
    Text
} from '@chakra-ui/react';


export const AuthComponent = () => {

    const { register, errors, signIn } = useAuth();





    return (
        <Container >
            <FormControl id="email" isRequired>
                <FormLabel
                    fontWeight='bold'
                    color='rgb(100,135,155)'
                >
                    E-mail
                </FormLabel>
                <Input
                    background='rgba(225,245,250,0.9)'
                    mb='20px'
                    defaultValue={localStorage.getItem('GAUSSBIT::EMAIL') ?? ''}
                    type="email"
                    placeholder="name@example.com"
                    onError={() => errors.email}
                    {...register('email')}
                />
            </FormControl>
            <FormControl id="password" isRequired>
                <FormLabel
                    color='rgb(100,135,155)'
                    fontWeight='bold'
                >
                    Password
                </FormLabel>
                <Input
                    background='rgba(225,245,250,0.9)'
                    defaultValue={''}
                    type="password"
                    placeholder="********"
                    onError={() => errors.password}
                    {...register('password')}
                />
            </FormControl>
            <Checkbox
                type="rememberMe"
                w="100%"
                colorScheme='blue'
                marginTop='10px'
                iconColor='white'
                borderColor='lightgray'
                defaultChecked
                color='rgb(100,135,155)'
                {...register('rememberMe')}
            >
                Remember me!
            </Checkbox>

            <Link w="100%"
                alignSelf="initial"
                marginTop='10px'
                textDecoration="none"
            >
                <Text
                    textAlign='end'
                    alignSelf="end"
                    fontSize='md'
                    fontWeight="normal"
                    color='rgb(100,135,155)'
                >
                    Forgot your password?
                </Text>
            </Link>
            <Button
                type='submit'
                marginTop="6"
                colorScheme="blue"
                minW='200px'
                w='100%'
                size='lg'
                color='#FFFFFF'
                fontSize='1rem'
                onClick={() => { signIn(); }}
            >
                Login
            </Button>

        </Container>
    );
}