import * as React from 'react';

import axios from 'axios';

import {
    createContext,
    Dispatch,
    ReactNode,
    SetStateAction,
    useContext,
    useEffect,
    useState
} from "react";

interface IAboutMeContextData {


}

interface IAboutMeProviderProps {
    children: ReactNode;
}

const AboutMeContext = createContext<IAboutMeContextData>({} as IAboutMeContextData);

export function AboutMeProvider(props: IAboutMeProviderProps) {

    useEffect(() => {

        unauth();

    }, []);

const unauth = () => {

    console.log("deslogado");
    
}

return (
    <AboutMeContext.Provider
        value={{


        }}>
        {props.children}
    </AboutMeContext.Provider>
)
}

export function useAboutMe() {

    const context = useContext(AboutMeContext);

    return context;
};