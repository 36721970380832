import * as React from 'react';

import axios from 'axios';

import {
    createContext,
    Dispatch,
    ReactNode,
    SetStateAction,
    useContext,
    useEffect,
    useState
} from "react";

interface IUploadDropzoneContextData {

    uploadImage(image: any): any;

}

interface IUploadDropzoneProviderProps {
    children: ReactNode;
}

const UploadDropzoneContext = createContext<IUploadDropzoneContextData>({} as IUploadDropzoneContextData);

export function UploadDropzoneProvider(props: IUploadDropzoneProviderProps) {

const uploadImage = (image: any) => {

    const payload = new FormData();

        payload.append('image', image, 'image.png');
        console.log(image);
    

    axios.post("https://gaussbit.com/wp-json/wp/v2/saveImgBlob", payload, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
        .then((response) => { console.log(response) });

}

return (
    <UploadDropzoneContext.Provider
        value={{

            uploadImage,

        }}>
        {props.children}
    </UploadDropzoneContext.Provider>
)
}

export function useUploadDropzone() {

    const context = useContext(UploadDropzoneContext);

    return context;
};