import * as React from 'react';

import { Container, ResultContainer } from './styles';

import Highlight from 'react-highlight'

import { useGPT } from '../../hooks/gpt.hook'

import {
    Button,
    FormControl,
    FormLabel,
    Input,
    Textarea,
    Checkbox,
    Text
} from '@chakra-ui/react';


export const GPTComponent = () => {

    const { register,
            errors, 
            GETGPT, 
            isSubmitting, 
            watchResult
        } = useGPT();


    return (
        <Container >
            <FormControl id="OPENAI_API_KEY" isRequired>
                <FormLabel
                    fontWeight='bold'
                    color='rgb(100,135,155)'
                >
                    API Key
                </FormLabel>
                <Input
                    background='rgba(225,245,250,0.9)'
                    mb='20px'
                    defaultValue={localStorage.getItem('GAUSSBIT::OPENAI_API_KEY') ?? ''}
                    type="password"
                    placeholder="Insert your API key . . ."
                    onError={() => errors.OPENAI_API_KEY}
                    {...register('OPENAI_API_KEY')}
                />
            </FormControl>
            <FormControl id="prompt" isRequired>
                <FormLabel
                    color='rgb(100,135,155)'
                    fontWeight='bold'
                >
                    Prompt
                </FormLabel>
                <Textarea
                    maxLength={512}
                    background='rgba(225,245,250,0.9)'
                    defaultValue={''}
                    placeholder="Insert your prompt . . ."
                    onError={() => errors.prompt}
                    {...register('prompt')}
                />
            </FormControl>
            <Checkbox
                type="rememberAPIKey"
                w="100%"
                colorScheme='blue'
                marginTop='10px'
                iconColor='white'
                borderColor='lightgray'
                defaultChecked
                color='rgb(100,135,155)'
                {...register('rememberAPIKey')}
            >
                Remember API Key?
            </Checkbox>
            <Button
                type='submit'
                marginTop="6"
                colorScheme="blue"
                minW='200px'
                w='100%'
                size='lg'
                mb='30px'
                color='#FFFFFF'
                fontSize='1rem'
                isLoading={isSubmitting}
                onClick={() => { GETGPT(); }}
            >
                Submit
            </Button>

            {watchResult !== null && watchResult !== undefined ?
                <ResultContainer>
                    <Highlight innerHTML={true}>
                        {watchResult}
                    </Highlight>
                </ResultContainer>
                :
                <div></div>
            }
        </Container>
    );
}