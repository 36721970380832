import * as React from 'react';
import * as ReactDOM from 'react-dom';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup'

import { useToast } from "@chakra-ui/react";

import {
    createContext,
    Dispatch,
    ReactNode,
    SetStateAction,
    useContext,
    useEffect,
    useState
} from "react";

import {
    FieldValues,
    useForm,
    UseFormGetValues,
    UseFormRegister,
    UseFormReset,
    UseFormSetValue
} from "react-hook-form";

interface IAuthContextData {

    signIn(): void;

    getValues: UseFormGetValues<FieldValues>;
    register: UseFormRegister<FieldValues>;
    reset: UseFormReset<FieldValues>;
    setValue: UseFormSetValue<FieldValues>;

    errors: { [x: string]: any };
    isSubmitting: boolean;

    watchMessage: any;
    watchFile: any;


}

interface IAuthProviderProps {
    children: ReactNode;
}

const AuthContext = createContext<IAuthContextData>({} as IAuthContextData);


const authSchema = yup.object().shape({
    email: yup.string().required().email(),
    password: yup.string().required(),
    rememberMe: yup.string().required(),
})


export function AuthProvider(props: IAuthProviderProps) {

    const toast = useToast();

    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        setError,
        reset,
        watch,
        formState: { errors, isSubmitting } }
        = useForm({
            resolver: yupResolver(authSchema)
        })

    const watchMessage = watch('message');
    const watchFile = watch('file');

    useEffect(() => {


        reset();


    }, []);



    function signIn() {

        toast({
            title: "Welcome to my website ! ! !",
            status: 'info',
            position: 'top',
            duration: 3000,
            isClosable: true
        })
        return;
    }

    return (
        <AuthContext.Provider
            value={{

                signIn,

                register,
                reset,
                setValue,
                getValues,
                errors,
                isSubmitting,

                watchMessage,
                watchFile,

            }}>
            {props.children}
        </AuthContext.Provider>
    )
}

export function useAuth() {

    const context = useContext(AuthContext);

    return context;
};