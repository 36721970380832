import * as React from 'react';
import * as ReactDOM from 'react-dom';

import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup';

import { useToast } from "@chakra-ui/react";

import axios from 'axios';

import {
    createContext,
    Dispatch,
    ReactNode,
    SetStateAction,
    useContext,
    useEffect,
    useState
} from "react";

import {
    FieldValues,
    useForm,
    UseFormGetValues,
    UseFormRegister,
    UseFormReset,
    UseFormSetValue
} from "react-hook-form";

import Pokedex from 'pokedex-promise-v2';

interface IPokemonContextData {

    pokesearch(): any;

    getValues: UseFormGetValues<FieldValues>;
    register: UseFormRegister<FieldValues>;
    reset: UseFormReset<FieldValues>;
    setValue: UseFormSetValue<FieldValues>;

    setResultView: any;
    resultView: any;
    setPokeError: any;
    pokeError: any;


    errors: { [x: string]: any };
    isSubmitting: boolean;

    watchMessage: any;
    watchFile: any;
    watchLocationArray: any;
}

interface IPokemonProviderProps {
    children: ReactNode;
}

const PokemonContext = createContext<IPokemonContextData>({} as IPokemonContextData);

const pokemonFormSchema = yup.object().shape({
    name: yup.string().required('Nome é necessário!'),
})

const P = new Pokedex();


export function PokemonProvider(props: IPokemonProviderProps) {

    const toast = useToast();

    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        setError,
        reset,
        watch,
        formState: { errors, isSubmitting } }
        = useForm({
            resolver: yupResolver(pokemonFormSchema)
        });

    const [resultView, setResultView] = useState(false);
    const [pokeError, setPokeError] = useState(false);

    const watchMessage = watch('message');
    const watchFile = watch('file');
    const watchLocationArray = watch('locationArray');

    useEffect(() => {

        reset();

    }, []);

    function pokesearch() {

        P.getPokemonByName(getValues('name').toLowerCase()) // with Promise
            .then((response: any) => {
                setValue('name', response);
                console.log(getValues('name'));
                axios.get(getValues('name').location_area_encounters)
                    .then(function (response: any) {
                        setValue('locationArray', response.data);
                    })
                    .catch(function (error: any) {
                        console.log('There was a location_area_encounters error', error);
                    });

            })
            .catch((error: any) => {
                setValue('name', { name: 'There was an ERROR: ' + error })
                setPokeError(true);
                setResultView(true);
            }).finally(() => {
                setResultView(true);

            }
            );
    }


    return (
        <PokemonContext.Provider
            value={{
                pokesearch,

                register,
                reset,
                setValue,
                getValues,
                errors,
                isSubmitting,

                watchMessage,
                watchFile,
                watchLocationArray,

                resultView,
                setResultView,
                pokeError,
                setPokeError,

            }}>
            {props.children}
        </PokemonContext.Provider>
    )
}

export function usePokemon() {

    const context = useContext(PokemonContext);

    return context;
};