import styled from "styled-components"


export const Container = styled.div`


    background: url("https://gaussbit.com/IMG/PNG/GBBackground.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    color: rgb(100,135,155);

    
    min-width:100%;
    width: 100%;
    min-height:100%;

    display: flex;
    flex-direction: column;
    font-family: 'Poppins', sans-serif;


    display:flex;
    flex-direction: column;
    justify-content:space-between;
    align-items: center;

    overflow-y: scroll;
    

   

`;