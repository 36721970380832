import * as React from 'react';

import { GPTComponent } from "../../../components/GPTComponent/GPTComponent";

import { GPTProvider } from "../../../hooks/gpt.hook";

export function GPTPlugin() {
    return (
        <GPTProvider>
            <GPTComponent />
        </GPTProvider>
    )
}