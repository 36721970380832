import * as React from 'react';

import { Container } from './styles';

import { Button, Text, Flex } from '@chakra-ui/react';

import { useAboutMe } from '../../hooks/aboutMe.hook';



export const AboutMeComponent = () => {

    return (
        <div>about me</div>
    )
}