import * as React from 'react';

import { MainComponent } from "../../../components/MainComponent/MainComponent";

import { MainProvider } from "../../../hooks/main.hook";

export function MainPlugin() {
  return (
    <MainProvider>
      <MainComponent />
    </MainProvider>
  )
}