import styled from "styled-components"


export const Container = styled.div`

    min-width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    background: rgba(225,245,250,0.9);
    border-radius:15px;
	box-shadow: 1px 2px 10px #000 ;
    margin: 5px;
    padding: 10px;

    textarea {
        min-height: 150px;
    }

`
export const ResultContainer = styled.div`

    background: lightgray;
    border-radius:15px;
    padding: 20px;
    color: black;

`