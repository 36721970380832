import * as React from 'react';

import axios from 'axios';

import {
    createContext,
    Dispatch,
    ReactNode,
    SetStateAction,
    useContext,
    useEffect,
    useState
} from "react";

import { useToast } from "@chakra-ui/react";


interface IUnauthContextData {


}

interface IUnauthProviderProps {
    children: ReactNode;
}

const UnauthContext = createContext<IUnauthContextData>({} as IUnauthContextData);

export function UnauthProvider(props: IUnauthProviderProps) {

    const toast = useToast();


    useEffect(() => {

        unauth();

    }, []);

const unauth = () => {

    console.log("unauthorized procedure complete!");
    toast({
        title: "Thanks for visiting my website ! ! !",
        status: 'info',
        position: 'top',
        duration: 3000,
        isClosable: true
    })
    return;

}

return (
    <UnauthContext.Provider
        value={{


        }}>
        {props.children}
    </UnauthContext.Provider>
)
}

export function useUnauth() {

    const context = useContext(UnauthContext);

    return context;
};