import * as React from 'react';

import { HomeComponent } from "../../../components/HomeComponent/HomeComponent";

import { HomeProvider } from "../../../hooks/home.hook";
import { UploadDropzoneProvider } from "../../../hooks/auxHooks/uploadDropzone.hook";

export function HomePlugin() {
    return (
        <HomeProvider>
            <UploadDropzoneProvider>
                <HomeComponent />
            </UploadDropzoneProvider>
        </HomeProvider>
    )
}