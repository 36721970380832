import * as React from 'react';

import { Container } from './styles';

import { useMain } from '../../hooks/main.hook'

import { Button } from '@chakra-ui/react';

import { NavBar } from '../NavBar/NavBar'
import { Footer } from '../Footer/Footer'

import { Outlet } from "react-router-dom";





export const MainComponent = () => {

  const { setValue, getValues, register, welcome } = useMain();

  return (
    <Container >

      <NavBar />
      
      <Outlet />
      
      <Footer />
    
    </Container>
  );
};