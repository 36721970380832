import * as React from 'react';

import { PokemonComponent } from "../../../components/PokemonComponent/PokemonComponent";

import { PokemonProvider } from "../../../hooks/pokemon.hook";

export function PokemonPlugin() {
  return (
    <PokemonProvider>
      <PokemonComponent />
    </PokemonProvider>
  )
}