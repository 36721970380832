import * as React from 'react';

import { Container } from './styles';

import { usePokemon } from '../../hooks/pokemon.hook'

import {
    Flex,
    Button,
    FormControl,
    FormLabel,
    Input,
    Checkbox,
    Link,
    Text,
    Image,
} from '@chakra-ui/react';
import { setDefaultResultOrder } from 'dns';

export const PokemonComponent = () => {

    const { setValue, getValues, register, reset, pokesearch, resultView, setResultView, setPokeError, pokeError, watchLocationArray } = usePokemon();

    return (
        <Container>
            {resultView ?
                pokeError ?
                    <Flex
                        flexDir='column'>
                        <h1>{getValues('name').name}</h1>
                        <Button
                            colorScheme='blue'
                            onClick={() => { reset(); setResultView(false); setPokeError(false); }}>
                            Try Another
                        </Button>
                    </Flex>
                    :
                    <Flex
                        flexDir='column'
                        justifyContent='center'
                        alignItems='center'
                    >
                        <h1>{getValues('name').name}</h1>
                        <Flex
                            flexDir='row'
                            flexWrap='wrap'
                            w='100%'
                            justifyContent='center'
                        >
                            <Flex
                                flexDir='column'
                                background='lightgray'
                                borderRadius='15px'
                                padding='5px'
                                margin='10px'
                                w='22%'
                            >
                                <Image src={getValues('name').sprites.front_default} height='100%' />
                                <Text
                                    textAlign='center'
                                >
                                    Male Default Front
                                </Text>
                            </Flex>
                            <Flex
                                flexDir='column'
                                background='lightgray'
                                borderRadius='15px'
                                padding='5px'
                                margin='10px'
                                w='22%'

                            >
                                <Image src={getValues('name').sprites.front_shiny} height='100%' />
                                <Text
                                    textAlign='center'
                                >
                                    Male Shiny Front
                                </Text>
                            </Flex>
                            <Flex
                                flexDir='column'
                                background='lightgray'
                                borderRadius='15px'
                                padding='5px'
                                margin='10px'
                                w='22%'

                            >
                                <Image src={getValues('name').sprites.front_female} height='100%' />
                                <Text
                                    textAlign='center'
                                >
                                    Female Default Front
                                </Text>
                            </Flex>
                            <Flex
                                flexDir='column'
                                background='lightgray'
                                borderRadius='15px'
                                padding='5px'
                                margin='10px'
                                w='22%'

                            >
                                <Image src={getValues('name').sprites.front_shiny_female} height='100%' />
                                <Text
                                    textAlign='center'
                                >
                                    Female Shiny Front
                                </Text>
                            </Flex>
                        </Flex>

                        <Flex
                            flexDir='column'
                            alignItems='center'
                            w='100%'
                            marginTop='10px'
                        >
                            <Text
                                textAlign='start'
                                w='50%'
                            >
                                Where to find: {watchLocationArray ? watchLocationArray.map((location: any, index: any) => {
                                    return (
                                        <Text>
                                            {location.location_area.name}
                                        </Text>

                                    )
                                })
                                    :
                                    'location array error'}
                            </Text>
                        </Flex>

                        <Flex
                            flexDir='column'
                            alignItems='center'
                            w='100%'
                            marginTop='10px'
                        >
                            <Text
                                w='50%'
                                textAlign='start'
                            >
                                Stats:
                            </Text>

                            {getValues('name').stats.map((stat: any) => {
                                return (
                                    <Flex
                                        w='50%'>
                                        <Text
                                            marginRight='10px'
                                            textAlign='start'
                                            w='100%'
                                        >
                                            {stat.stat.name}:
                                        </Text>
                                        <Text
                                            w='100%'
                                            textAlign='start'>
                                            {stat.base_stat}
                                        </Text>
                                    </Flex>
                                );
                            })}

                        </Flex>

                        <Flex
                            flexDir='row'
                            alignItems='center'
                            justifyContent='center'
                            w='50%'
                            marginTop='10px'
                        >
                            <Text
                                w='50%'
                                textAlign='start'
                                marginRight='10px'
                            >
                                Weight:
                            </Text>
                            <Text
                                w='50%'>
                                {getValues('name').weight}
                            </Text>
                        </Flex>

                        <Flex
                            flexDir='row'
                            alignItems='center'
                            justifyContent='center'
                            w='50%'
                            marginTop='10px'
                        >
                            <Text
                                w='50%'
                                textAlign='start'
                                marginRight='10px'
                            >
                                Height:
                            </Text>
                            <Text
                                w='50%'>
                                {getValues('name').height}
                            </Text>
                        </Flex>

                        <Flex
                            flexDir='column'
                            alignItems='center'
                            justifyContent='center'
                            w='50%'
                            marginTop='10px'
                        >
                            <Text
                                w='100%'
                                textAlign='start'
                                marginRight='10px'
                            >
                                Abilities:
                            </Text>

                            <Flex
                                flexDir='column'
                                alignItems='center'
                                justifyContent='center'
                                w='50%'
                                marginTop='10px'
                            >
                                {getValues('name').abilities.map((ability: any) => {
                                    return (
                                        <Text
                                            w='100%'>
                                            {ability.ability.name}
                                        </Text>
                                    );
                                })}
                            </Flex>
                        </Flex>

                        <Flex
                            flexDir='column'
                            alignItems='center'
                            justifyContent='center'
                            w='50%'
                            marginTop='10px'
                        >
                            <Text
                                w='100%'
                                textAlign='start'
                                marginRight='10px'
                            >
                                Type:
                            </Text>
                            <Flex
                                flexDir='column'
                                alignItems='center'
                                justifyContent='center'
                                w='50%'
                                marginTop='10px'
                            >
                                {getValues('name').types.map((type: any) => {
                                    return (
                                        <Text
                                            w='100%'>
                                            {type.type.name}
                                        </Text>
                                    );
                                })}
                            </Flex>
                        </Flex>

                        <Flex
                            flexDir='column'
                            alignItems='center'
                            justifyContent='center'
                            w='50%'
                            marginTop='10px'
                        >
                            <Text
                                w='100%'
                                textAlign='start'
                                marginRight='10px'
                            >
                                Moves:
                            </Text>

                            <Flex
                                flexDir='column'
                                alignItems='center'
                                justifyContent='center'
                                w='50%'
                                marginTop='10px'
                            >
                                {getValues('name').moves.sort(function (a: any, b: any) {
                                    if (a.move.name.toLowerCase() < b.move.name.toLowerCase()) return -1;
                                    if (a.move.name.toLowerCase() > b.move.name.toLowerCase()) return 1;
                                    return 0;
                                }).map((move: any) => {
                                    return (
                                        <Text
                                            w='100%'>
                                            {move.move.name}
                                        </Text>
                                    );
                                })}
                            </Flex>
                        </Flex>

                        <Button
                            colorScheme='blue'
                            onClick={() => { reset(); setResultView(false); }}>
                            Try Another
                        </Button>
                    </Flex>

                :


                <Flex
                    flexDir='column'>
                    <h1> Use the search bar to find your favorite pokémon </h1>

                    <Flex
                        flexDir='column'>
                        <Input
                            background='rgba(225,245,250,0.9)'
                            type="text"
                            placeholder="Example: ditto"
                            required
                            {...register('name')}
                        />

                        <Button
                            type="submit"
                            colorScheme='blue'
                            onClick={() => { pokesearch(); }}
                        >
                            Search
                        </Button>

                    </Flex>
                </Flex>
            }

        </Container >

    )
}