import * as React from 'react';

import { Container, SubContainer, Subscript } from './styles';

export const Footer = () => {



    return (
        <Container>
            <SubContainer>
                <p> Here's where I work:</p>
                <p>
                    <a href="https://www.contemplato.com/" rel="noopener">
                        <img src="https://gaussbit.com/IMG/SVG/LogoContemplato.svg" alt='' height='40px' />
                    </a>
                </p>
                <Subscript>
                    Written by kokakin.
                </Subscript>

            </SubContainer>

        </Container>

    )
}