import styled from "styled-components"


export const Container = styled.div`

	width: 100%;
    display:flex;
	flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;

	
`;

export const NavButton = styled.a`

	color: rgb(100,135,155);
	background: rgba(225,245,250,0.9);
	border-radius:15px;
	box-shadow: 1px 2px 10px #000 ;
	text-align: center;
	text-decoration: none;
	padding: 5px;
	margin: 10px;

`;