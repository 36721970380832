import styled from "styled-components"


export const Container = styled.div`

    width:80%;
    display: flex;
    flex-direction: column;
    align-items:center;
    height: 100%;
    color: rgb(100,135,155);

    >img {
        align-self:center;
        height: 50px;
        width: 50px;
        pointer-events: none;
        animation: App-logo-spin infinite 20s linear;

        @keyframes App-logo-spin {
            from {
                transform: rotate(0deg);
            }
            to {
                transform: rotate(360deg);
            }
        }

    }

`

export const QuillStyle = styled.div`

    display: 'flex';
    flex: 1;
    flex-direction: 'column';

    .ql-container {

        min-height: 100px;
    };

    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='8px']::before,
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='8px']::before {
    content: '8px';
    };
    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='9px']::before,
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='9px']::before {
    content: '9px';
    };
    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='10px']::before,
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='10px']::before {
    content: '10px';
    };
    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='12px']::before,
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='12px']::before {
    content: '12px';
    };
    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='14px']::before,
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='14px']::before {
    content: '14px';
    };
    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='16px']::before,
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='16px']::before {
    content: '16px';
    };
    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='20px']::before,
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='20px']::before {
    content: '20px';
    };
    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='24px']::before,
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='24px']::before {
    content: '24px';
    };
    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='32px']::before,
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='32px']::before {
    content: '32px';
    };
    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='42px']::before,
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='42px']::before {
    content: '42px';
    };
    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='54px']::before,
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='54px']::before {
    content: '54px';
    };
    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='68px']::before,
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='68px']::before {
    content: '68px';
    };
    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='84px']::before,
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='84px']::before {
    content: '84px';
    };
    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='98px']::before,
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='98px']::before {
    content: '98px';
    };


    .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=arial]::before,
    .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=arial]::before {
    content: 'Arial';
    };
    .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=inconsolata]::before,
    .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=inconsolata]::before {
    content: 'Inconsolata';
    };
    .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=sans-serif]::before,
    .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=sans-serif]::before {
    content: '';
    };
    .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=serif]::before,
    .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=serif]::before {
    content: 'Serif';
    };
    .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=monospace]::before,
    .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=monospace]::before {
    content: 'Monospace';
    };
    .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=mirza]::before,
    .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=mirza]::before {
    content: 'Mirza';
    };
    .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=roboto]::before,
    .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=roboto]::before {
    content: 'Roboto';
    };
    .ql-snow .ql-picker.ql-font .ql-picker-item::before {
    font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    };

    .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=sans-serif]::before {
    font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    };
    .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=serif]::before {
    font-family: 'Georgia', 'Times New Roman', serif;
    };
    .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=monospace]::before {
    font-family: 'Monaco', 'Courier New', monospace;
    };
    .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=insonsolata]::before {
    font-family: 'Inconsolata', sans-serif;
    };
    .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=mirza]::before {
    font-family: 'Mirza', sans-serif;
    };
    .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=roboto]::before {
    font-family: 'Roboto', sans-serif;
    };



`