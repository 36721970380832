import * as React from 'react';

import { Container, QuillStyle } from './styles';

import { useHome } from '../../hooks/home.hook'

import { Button, Text, Flex, Image } from '@chakra-ui/react';

import ReactQuill from 'react-quill';
import { Quill } from 'react-quill';
import { Helmet } from 'react-helmet';

import { UploadDropzonePlugin } from '../../pages/plugins/auxPlugins/uploadDropzone'
import { relative } from 'path';


const AlignStyle = Quill.import('attributors/style/align');
Quill.register(AlignStyle, true);
const FontStyle = Quill.import('attributors/style/font');
Quill.register(FontStyle, true);
const ColorStyle = Quill.import('attributors/style/color');
Quill.register(ColorStyle, true);
const Font = Quill.import('formats/font');
Font.whitelist = ['arial', 'inconsolata', 'roboto', 'mirza', 'sans-serif'];
Quill.register(Font, true);

const Size = Quill.import('attributors/style/size');
Size.whitelist = ['8px', '9px', '10px', '12px', '14px', '16px', '20px', '24px', '32px', '42px', '54px', '68px', '84px', '98px'];
Quill.register(Size, true);

const modules = {
    toolbar: [
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ 'size': ['8px', '9px', '10px', '12px', '14px', '16px', '20px', '24px', '32px', '42px', '54px', '68px', '84px', '98px'] }],
        [{ 'font': ['arial', 'inconsolata', 'roboto', 'mirza', 'serif', 'monospace'] }],
        [{ 'align': [] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'list': 'bullet' }, { 'list': 'ordered' }],
        // [{ 'indent': '-1'}, { 'indent': '+1' }],
        [],
        [{ 'color': [] }, { 'background': [] }],
        ['link', 'image', 'video'],
        ['clean']
    ],
};

export const HomeComponent = () => {

    const {
        saveQuill,
        errors,
        watchMessage,
        onEditorStateChange
    } = useHome();



    return (
        <Container >
            <Helmet>
                <link rel="stylesheet" href="//cdn.quilljs.com/1.3.6/quill.snow.css" />
            </Helmet>
            <Flex
                w='100%'
                flex='1'
            >
                <QuillStyle>
                    <ReactQuill
                        modules={modules}
                        value={watchMessage}
                        placeholder='Mensagem Necessária'
                        style={{
                            background: 'rgba(225,245,250,0.9)',
                            overflowY: 'auto',
                            border: "solid 1px",
                            borderRadius: '15px',

                        }}
                        onChange={onEditorStateChange}
                    />
                    <Button
                        w='100%'
                        color='rgb(100,135,155)'
                        background='rgba(225,245,250,0.9)'
                        borderRadius='15px'
                        boxShadow='1px 2px 10px #000'
                        textDecoration='none'
                        padding='10px'
                        margin='10px auto 10px auto'

                        onClick={() => { saveQuill(); }}
                    >
                        saveQuill
                    </Button>
                </QuillStyle>
                <Text color='red.400' mt='5px'>
                    {errors.message && `${errors.message.message}`}
                </Text>
            </Flex>


            <Flex
                w='100%'
                margin='0 auto 0 auto'>

                <Button
                    w='100%'
                    color='rgb(100,135,155)'
                    background='rgba(225,245,250,0.9)'
                    borderRadius='15px'
                    boxShadow='1px 2px 10px #000'
                    textDecoration='none'
                    padding='10px'
                    margin='10px'

                    onClick={() => { console.log('random image') }}
                >
                    Random Image
                </Button>
            </Flex>
        </Container>
    );
}