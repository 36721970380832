import * as React from 'react';

import { UnauthComponent } from "../../../../components/auxComponents/UnauthComponent/UnauthComponent";

import { UnauthProvider } from "../../../../hooks/auxHooks/unauth.hook";

export function UnauthPlugin() {
    return (
        <UnauthProvider>
            <UnauthComponent />
        </UnauthProvider>
    )
}