import * as React from 'react';

import { Container} from './styles';

import { Button, Text, Flex } from '@chakra-ui/react';

import { useUnauth } from '../../../hooks/auxHooks/unauth.hook';

import { Link } from "react-router-dom";


export const UnauthComponent = () => {

    return (

        <Container>

            <Button
                w='100%'
                color='rgb(100,135,155)'
                background='rgba(225,245,250,0.9)'
                borderRadius='15px'
                boxShadow='1px 2px 10px #000'
                textDecoration='none'
                padding='10px'
                margin='10px'
                onClick={() => {}}
            >
                <Link to="/auth" >Back to Login ! </Link>
            </Button>

        </Container>

    )
}