import * as React from 'react';

import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup';

import { useToast } from "@chakra-ui/react";

import {
    createContext,
    Dispatch,
    ReactNode,
    SetStateAction,
    useContext,
    useEffect,
    useState
} from "react";

import {
    FieldValues,
    useForm,
    UseFormGetValues,
    UseFormRegister,
    UseFormReset,
    UseFormSetValue
} from "react-hook-form";

import FileSaver from 'file-saver';

interface IHomeContextData {

    onEditorStateChange(message: any): void;
    saveQuill(): void;

    getValues: UseFormGetValues<FieldValues>;
    register: UseFormRegister<FieldValues>;
    reset: UseFormReset<FieldValues>;
    setValue: UseFormSetValue<FieldValues>;

    errors: { [x: string]: any };
    isSubmitting: boolean;

    watchMessage: any;
    watchFile: any;
}

interface IHomeProviderProps {
    children: ReactNode;
}

const HomeContext = createContext<IHomeContextData>({} as IHomeContextData);

const homeFormSchema = yup.object().shape({
    name: yup.string().required('Nome é necessário!'),
    createdAt: yup.string().required('necessário!'),
    updatedAt: yup.string().required('necessária!'),
})


export function HomeProvider(props: IHomeProviderProps) {

    const toast = useToast();

    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        setError,
        reset,
        watch,
        formState: { errors, isSubmitting } }
        = useForm({
            resolver: yupResolver(homeFormSchema)
        });

    const watchMessage = watch('message');
    const watchFile = watch('file');

    useEffect(() => {
        reset();
    }, []);

    const saveQuill = async () => {
        const blob = new Blob([watchMessage], { type: 'text/html;charset=utf-8' });
        FileSaver.saveAs(blob, 'hello.html');

    }

    const onEditorStateChange = (editorState: any) => {
        setValue('message', editorState);
    }

    return (
        <HomeContext.Provider
            value={{
                onEditorStateChange,
                saveQuill,

                register,
                reset,
                setValue,
                getValues,
                errors,
                isSubmitting,

                watchMessage,
                watchFile,
            }}>
            {props.children}
        </HomeContext.Provider>
    )
}

export function useHome() {

    const context = useContext(HomeContext);

    return context;
};