import * as React from 'react';
import * as ReactDOM from 'react-dom';

import axios from 'axios';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup'

import { useToast } from "@chakra-ui/react";

import {
    createContext,
    Dispatch,
    ReactNode,
    SetStateAction,
    useContext,
    useEffect,
    useState
} from "react";

import {
    FieldValues,
    useForm,
    UseFormGetValues,
    UseFormRegister,
    UseFormReset,
    UseFormSetValue
} from "react-hook-form";

interface IGPTContextData {

    GETGPT(): Promise<void>;

    getValues: UseFormGetValues<FieldValues>;
    register: UseFormRegister<FieldValues>;
    reset: UseFormReset<FieldValues>;
    setValue: UseFormSetValue<FieldValues>;

    errors: { [x: string]: any };
    isSubmitting: boolean;

    watchOPENAI_API_KEY: any;
    watchPrompt: any;
    watchResult: any;


}

interface IGPTProviderProps {
    children: ReactNode;
}

const GPTContext = createContext<IGPTContextData>({} as IGPTContextData);


const gptSchema = yup.object().shape({
    OPENAI_API_KEY: yup.string().required(),
    prompt: yup.string().required(),
    rememberAPIKey: yup.boolean().required(),
})


export function GPTProvider(props: IGPTProviderProps) {

    const toast = useToast();

    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        setError,
        reset,
        watch,
        formState: { errors, isSubmitting } }
        = useForm({
            resolver: yupResolver(gptSchema)
        })

    const watchOPENAI_API_KEY = watch('OPENAI_API_KEY');
    const watchPrompt = watch('prompt');
    const watchResult = watch('result');

    useEffect(() => {

        reset();

    }, []);



    const GETGPT = async () => {

        if (getValues('rememberAPIKey')) {
            if(watchOPENAI_API_KEY){

                localStorage.setItem("GAUSSBIT::OPENAI_API_KEY", watchOPENAI_API_KEY);
            
            }
        } else {
            localStorage.removeItem("GAUSSBIT::OPENAI_API_KEY");
        }

        try {
            const response = await axios.post('https://api.openai.com/v1/completions', {
                prompt: watchPrompt,
                model: "text-davinci-003",
                max_tokens: 1024,
                n: 1,
                temperature: 0.2
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + watchOPENAI_API_KEY,
                }
            });
            console.log(response.data.choices[0].text);
            setValue('result', response.data.choices[0].text.replace(/\n/g, "<br>"));
        } catch (error: any) {
            console.error(error);
        }

    };



    return (
        <GPTContext.Provider
            value={{

                GETGPT: handleSubmit(GETGPT),

                register,
                reset,
                setValue,
                getValues,
                errors,
                isSubmitting,

                watchOPENAI_API_KEY,
                watchPrompt,
                watchResult,

            }}>
            {props.children}
        </GPTContext.Provider>
    )
}

export function useGPT() {

    const context = useContext(GPTContext);

    return context;
};