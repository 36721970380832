import * as React from 'react';
import * as ReactDOM from 'react-dom';

import * as yup from 'yup'

import { yupResolver } from '@hookform/resolvers/yup';

import { useToast } from "@chakra-ui/react";


import { createContext,
            Dispatch, 
            ReactNode, 
            SetStateAction, 
            useContext, 
            useEffect, 
            useState } from "react";

import { FieldValues,
         useForm, 
         UseFormGetValues, 
         UseFormRegister, 
         UseFormReset, 
         UseFormSetValue } from "react-hook-form";

interface IMainContextData {

    welcome(): void;

    getValues: UseFormGetValues<FieldValues>;
    register: UseFormRegister<FieldValues>;
    reset: UseFormReset<FieldValues>;
    setValue: UseFormSetValue<FieldValues>;

    errors: { [x: string]: any };
    isSubmitting: boolean;

    watchMessage: any;
    watchFile: any;

    
}

interface IMainProviderProps {
    children: ReactNode;
}

const MainContext = createContext<IMainContextData>({} as IMainContextData);

const mainFormSchema = yup.object().shape({
    name: yup.string().required('Nome é necessário!'),
    createdAt: yup.string().required('necessário!'),
    updatedAt: yup.string().required('necessária!'),
})


export function MainProvider(props: IMainProviderProps) {


    const toast = useToast();


    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        setError,
        reset,
        watch,
        formState: { errors, isSubmitting } }
        = useForm({
            resolver: yupResolver(mainFormSchema)
        })

        const watchMessage = watch('message');
        const watchFile = watch('file');

    useEffect(() => {

        
        reset();


    }, []);


    function welcome() {

        toast({
            title: "Welcome to my website ! ! !",
            status: 'info',
            position: 'top',
            duration: 3000,
            isClosable: true
          })
          return;
    }

    return (
        <MainContext.Provider
            value={{
                welcome,

                register,
                reset,
                setValue,
                getValues,
                errors,
                isSubmitting,

                watchMessage,
                watchFile,

            }}>
            {props.children}
        </MainContext.Provider>
    )
}

export function useMain() {

    const context = useContext(MainContext);

    return context;
};