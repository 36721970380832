import styled from "styled-components"


export const Container = styled.div`

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    color: rgb(100,135,155);
    background: rgba(225,245,250);
    padding: 10px;
    margin: 10px;
    border-radius:15px;
    box-shadow: 1px 2px 10px #000 ;
`

export const SubContainer = styled.div`

    height:5rem;
    width:220px;
    background: rgba(225,245,250,0.9);
    border-radius:15px;
    box-shadow: 1px 2px 10px #000 ;
    margin: 5px;
    padding: 10px;

    display: flex;
    flex-direction: column;
    text-align:center;
    justify-content: center;

`